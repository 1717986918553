import { useEffect, useState, useContext } from 'react';
import Search from '../assets/search_icon.svg';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import WhatsApp from '../assets/svg/header/whatsapp.svg';
import * as language from '../constants/languages';
import { getSupportData } from '../services/services';
import { AuthContext } from '../pages/RootPage';
import '../styles/HeaderRoot.css'
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Logo from "../assets/ULCPLogo.png"

export default function HeaderRoot({ opensnack }) {
    const navigate = useNavigate()
    const { toggleDrawer } = useContext(AuthContext);
    const [whatsAppNo, setwhatsAppNo] = useState("")
    const [supportNumber, setsupportNumber] = useState("")
    const [searchname, setsearchname] = useState("")
    const [logo, setlogo] = useState('')
    useEffect(() => {
        getCompanyInfo();
        getSupportInfoData();
    })

    //get sotre name
    async function getCompanyInfo() {

        const value = localStorage.getItem('companyInfo')

        if (value === null || value === undefined) {
            setwhatsAppNo("")
        } else {
            let companyInfo = JSON.parse(value);
            setwhatsAppNo(companyInfo.whatsAppNo)
            setlogo(companyInfo.logo)
        }

    }

    async function getSupportInfoData() {

        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {
                setsupportNumber(supportData.data.contactNos)
            }
        })
    }

    async function openWhatsapp() {
        if (isMobile) {
            window.open('whatsapp://send?phone=' + whatsAppNo)
        }
        else {
            window.open(`https://web.whatsapp.com/send?phone=${whatsAppNo}`)
        }
    }

    async function callNow() {
        window.open(`tel:${supportNumber}`)
    }
    async function searchProduct() {
        if (searchname.trim().length == 0) {
            opensnack('Please enter a search term')
        }
        else {
            navigate(`/Search/${searchname}`)
        }

    }

    function keydown(e) {
        if (e.key === "Enter") {
            searchProduct()
        }
    }
    return (

        <div className='HeaderRoot'>
            <div className="HeaderBg">
                <div className='drawerIconHeaderRoot' >
                    <img src={DrawerIcon} style={{ width: 20, height: 20 }} onClick={() => toggleDrawer()} />
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <img src={logo } height='auto' width={150} />
                </div>
                <div style={{ justifyContent: 'center', alignItems: 'center', }} onClick={() => openWhatsapp()} >

                    <img src={WhatsApp} style={{ width: 45, height: 45, }} />
                </div>

                <div className="CallNow" onClick={() => callNow()}>
                    <p style={{ margin: 0, color: "#fff" }}>Call Now</p>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBlock: 30, }}>
                <input type="text"
                    placeholder={language.searchLabel}
                    style={{ padding: 10, width: '70%' }}
                    className='SearchField'
                    autoCapitalize={'none'}
                    onChange={(event) => setsearchname(event.target.value)}
                    inputMode='search'
                    onKeyDown={(e) => keydown(e)}
                />
                <div style={{ backgroundColor: '#1d4e4b', alignItems: 'center', display: 'flex', paddingInline: 15, paddingBlock: 5, borderRadius: 7 }}>
                    <img src={Search} onClick={() => searchProduct()} />
                </div>

            </div>
        </div>

    )

}